import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss'],
})
export class NoInternetComponent implements OnInit {
  requestTrackerId: string = '';

  constructor(
    private toaster: ToasterService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.requestTrackerId = this.route.snapshot.queryParamMap.get('correlationId');
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.requestTrackerId);
    this.toaster.success('Request tracker id copied!');
  }
}
