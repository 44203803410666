import { mapEnumToOptions } from '@abp/ng.core';

export enum missionStatus {
  CustomerRequest = 'Customer Request',
  ReadyForSourcing = 'Ready for Sourcing',
  PilotSourcing = 'Pilot Sourcing',
  LogisticsCoordination = 'Logistics Coordination',
  AwaitingFligth = 'Awaiting Flight',
  PilotCheckedIn = 'Pilot Checked In',
  PilotOnSite = 'Pilot On Site',
  PilotLeftSite = 'Pilot Left Site',
  DataUploading = 'Data Uploading',
  DataProccessing = 'Data Processing',
  QAQC = 'QA QC',
  ReadyToInvoice = 'Ready to Invoice',
  RequestRefly = 'Request Refly',
  OnHold = 'On Hold',
  Cancelled = 'Cancelled',
  PilotPending = 'Pilot Pending',
  PilotAssigned = 'Pilot Assigned',
  QAQCPending = 'QAQC Pending',
  QAQCApproved = 'QAQC Approved',
  QAQCNotApproved = 'QAQC Rejected',
  AwaitingMCAssignment = 'Awaiting MC Assignment',
  AwaitingQAQCAssignment = 'Awaiting QAQC Assignment',
  DEAD = 'Dead',
  ClientInvoiced = 'Client Invoiced',
  UnderMCReview = 'Under MC Review',
  AirspacePendingPilotRequest = 'Airspace Pending Pilot Request',
  AirspaceRequested = 'Airspace Pending FAA Approval',
  AirspaceApproved = 'Airspace Approved',
  Shipping = 'Shipping',
}

export const missionStatusOptions = mapEnumToOptions(missionStatus);
