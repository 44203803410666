<div class="compact-mission-card">
  <div class="compact-mission-card__header">
    <p><span class="compact-mission-card__small-text">Job Id: </span> {{ mission.jobId }}</p>

    <p class="compact-mission-card__tag compact-mission-card__tag--pull-left">
      {{ mission.statesShowPilotsDesc }}
    </p>

    <div class="compact-mission-card__action--secondary" *ngIf="mission.captureStatus != 1">
      <button class="compact-mission-card__menu" [matMenuTriggerFor]="menu">
        <span class="material-symbols-outlined">more_vert</span>
      </button>

      <mat-menu #menu="matMenu" [class]="'secondary-actions mat-elevation-z2'">
        <ng-content select="[secondaryActionsMenu]"></ng-content>
      </mat-menu>
    </div>
  </div>

  <div class="compact-mission-card__body">
    <div class="compact-mission-card__icon-description">
      <span class="material-symbols-rounded"> distance </span>
      {{ mission.address }}
    </div>

    <div class="compact-mission-card__icon-description">
      <span class="material-symbols-rounded"> calendar_today </span>
      {{ captureDate }}
    </div>
  </div>

  <div class="compact-mission-card__actions">
    <div class="compact-mission-card__action--primary">
      <ng-content select="[primaryAction]"></ng-content>
    </div>
  </div>
</div>
