import { ABP } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
  enumState,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { switchMap } from 'rxjs/operators';
import { DataTablePagerComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent implements OnInit {
  @ViewChild('pager') pager: DataTablePagerComponent;
  @Input() maxResultCount: number;
  @Output() maxResultCountChange = new EventEmitter<number>();
  @Input() clientMaxResultCount: number;
  @Output() clientMaxResultCountChange = new EventEmitter<number>();
  @Input() rowCount: number;

  @Input() pageSize: number;
  @Input() pageNumber: number;
  @Output() pageNumberChange = new EventEmitter<any>();
  @Input() customRange: number[];
  valuesMaxResultCount: number[];
  @Input() curPage: number;
  @Input() dataTable: any;
  @Input() customPageSize: boolean = false;
  maxCustomPageSize: number = 1000;
  customizing: boolean = false;
  defaultCustomSize: number = 10;
  customSizeSelected: number = 10;

  private readonly PAGE_SIZE_ATTR = 'PAGESIZE';
  private readonly MAX_CUSTOM_PAGE_SIZE_ATTR = 'MAXCUSTOMSIZE';

  @Output() beforePageSizeChange = new EventEmitter<any>();

  constructor(
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
  ) {}

  ngOnInit(): void {
    this.getMaxPageSize();
    this.getPageAttributeValues();
  }

  private getPageAttributeValues(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.PAGE_SIZE_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration?.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.valuesMaxResultCount = attribute.description.split(',').map(val => parseInt(val, 10));
        this.defaultCustomSize = this.valuesMaxResultCount[0];
      });
  }

  private getMaxPageSize(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.MAX_CUSTOM_PAGE_SIZE_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration?.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.maxCustomPageSize = parseInt(attribute.description);
      });
  }

  pageSizeSelected() {
    this.beforePageSizeChange?.emit(0);
    this.defaultCustomSize = this.clientMaxResultCount;
    this.dataTable?.onFooterPage({ page: 1 }); // Reset table to page 1
    this.pageNumberChange?.emit(0);
    this.clientMaxResultCountChange?.emit(this.clientMaxResultCount);
    this.maxResultCountChange?.emit(this.clientMaxResultCount);
  }

  customPageSizeSelected() {
    if (
      this.customSizeSelected <= this.maxCustomPageSize &&
      this.customSizeSelected > 0 &&
      Number.isInteger(this.customSizeSelected)
    ) {
      this.clientMaxResultCount = this.customSizeSelected;
      this.pageSizeSelected();
    }
  }

  toggleCustom() {
    this.customizing = !this.customizing;
    if (!this.customizing) {
      this.clientMaxResultCount = this.valuesMaxResultCount[0];
      this.pageSizeSelected();
    }
  }

  /**
   * Gets a readable range of the current dataset
   * @returns string
   */
  getRange(): string {
    const isLastPage = this.pager?.totalPages === this.curPage;
    const lowerRange = this.dataTable?.offset * this.pageSize + 1;
    // We do direct DOM-querying here because the value given from the component is not reliable
    const rowCount = this.dataTable?.element.querySelectorAll('.datatable-body-row')?.length || 0;
    const rangeCount = rowCount * this.curPage;
    const lastRangeCount = rowCount + lowerRange - 1;

    const endRange = isLastPage ? lastRangeCount : rangeCount;

    return `${lowerRange} - ${endRange}`;
  }
}
