<div class="mt-3">
  <lib-questionnaire
    [UserId]="pilotId"
    DataLink="wizard"
    QuestionnaireName="Pilot Questionnaire"
    DisplayInList="true"
  >
    <h3 header>Questionnaire</h3>
  </lib-questionnaire>
</div>
