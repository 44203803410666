<div class="col mt-3">
  <div class="mb-4">
    <app-equipments [pilotId]="pilotId"></app-equipments>
  </div>
  <div class="mb-4">
    <div class="card-body card-container">
      <div class="d-flex justify-content-between mb-2">
        <h3>{{ 'pilotsService::Geofence' | abpLocalization }}</h3>
      </div>
      <flg-geofence
        (added)="handleGeofenceAdd($event)"
        (removed)="handleRemoveGeofence($event)"
        [maxSize]="804670"
        [options]="{
          mapTypeId: 'roadmap',
        }"
      ></flg-geofence>
      <div class="d-flex justify-content-end">
        <button (click)="handleSaveChanges()" mat-flat-button class="common-button" type="button">
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
