<div class="card" *ngIf="pilot?.id != undefined">
  <div class="card-body z-index-0">
    <div class="row">
      <div class="col-12 col-md-4 col-lg-3 col">
        <app-fg-breadcrumb [breadcrumbs]="[{ text: 'Pilot Profile' }]"></app-fg-breadcrumb>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 col-lg-3 col">
        <app-pilot-info-card [pilot]="pilot"></app-pilot-info-card>
      </div>
      <div class="col-12 col-md-8 col-lg-9 col">
        <mat-tab-group (selectedTabChange)="handleTabChange()">
          <mat-tab label="{{ 'pilotsService::ProfileOverview' | abpLocalization }}">
            <app-pilot-profile-overview [pilotId]="pilotId"></app-pilot-profile-overview
          ></mat-tab>
          <mat-tab label="{{ 'pilotsService::Questionnaire' | abpLocalization }}">
            <app-pilot-profile-questionnaire [pilotId]="pilotId"></app-pilot-profile-questionnaire
          ></mat-tab>
          <mat-tab label="{{ 'pilotsService::MyDocuments' | abpLocalization }}">
            <app-pilot-profile-documents
              [pilotId]="pilotId"
              [pilot]="pilot"
            ></app-pilot-profile-documents
          ></mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
