<mat-card style="text-align: center">
  <img class="main-image" src="assets/images/flyguys-pilot-profile-bg.png" />
  <div class="avatar-container">
    <img class="avatar" [src]="image" />
  </div>

  <mat-card-title class="fs-16 text-break">
    {{ pilot.firstName + ' ' + pilot.lastName }}
  </mat-card-title>
  <mat-card-subtitle class="fs-12">
    {{ 'pilotsService::Pilot' | abpLocalization }}
  </mat-card-subtitle>

  <mat-card-content class="mt-4" style="text-align: left">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="fs-14 text-break m-0">
        {{ 'pilotsService::PersonalInformation' | abpLocalization }}
      </h3>
      <span class="fs-24 material-symbols-rounded cursor-pointer" (click)="create()"> edit </span>
    </div>
    <label class="fs-12 text-break" for="pilot-card-email">
      {{ 'pilotsService::Email' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2" id="pilot-card-email">{{ pilot.email }}</p>

    <label class="fs-12" for="pilot-card-phone">
      {{ 'pilotsService::PrimaryPhoneNumber' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2" id="pilot-card-phone">{{ pilot.phone }}</p>

    <label class="fs-12" for="pilot-card-phone">
      {{ 'pilotsService::SecondaryPhoneNumber' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2" id="pilot-card-phone">{{ pilot.secondaryPhone }}</p>

    <label class="fs-12" for="pilot-card-address">
      {{ 'pilotsService::PrimaryAddress' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2" id="pilot-card-address">{{ pilot.address }}</p>

    <label class="fs-12">
      {{ 'pilotsService::ZipCode' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2">{{ pilot.addressZipCode }}</p>

    <label class="fs-12" for="pilot-card-address">
      {{ 'pilotsService::SecondaryAddress' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2" id="pilot-card-address">{{ pilot.secondaryAddress }}</p>

    <label class="fs-12" for="pilot-card-address">
      {{ 'pilotsService::Gender' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2" id="pilot-card-address">{{ pilot.gender }}</p>

    <label class="fs-12" for="pilot-card-address">
      {{ 'pilotsService::Ethnicity' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2" id="pilot-card-address">{{ pilot.ethnicity }}</p>

    <label class="fs-12" for="pilot-card-availabilityId">
      {{ 'pilotsService::Availability' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2" id="pilot-card-availabilityId">
      {{ availabilitiesDict[pilot.availabilityId] }}
    </p>

    <label class="fs-12" for="pilot-part107Number">
      {{ 'pilotsService::FAA107' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2" id="pilot-part107Number">{{ pilot.part107Number }}</p>

    <label class="fs-12" for="pilot-enableSMSReception">
      {{ 'IdentityService::EnableSMSReception' | abpLocalization }}
    </label>
    <p class="fs-14 text-break mb-2" id="pilot-enableSMSReception">
      {{
        pilot?.enableSMSReception == null ? 'No' : pilot.enableSMSReception == true ? 'Yes' : 'No'
      }}
    </p>
  </mat-card-content>
</mat-card>

<ng-template #modal>
  <flg-generic-modal>
    <ng-container modalTitle>
      {{ 'pilotsService::PilotInformation' | abpLocalization }}
    </ng-container>

    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form [formGroup]="form" id="pilot" skipValidation>
          <mat-form-field appearance="outline" floatLabel="always" class="w-100">
            <mat-label>{{ 'pilotsService::Username' | abpLocalization }}</mat-label>
            <input
              matInput
              placeholder="{{ 'pilotsService::Username' | abpLocalization }}"
              [value]="pilot.username"
              disabled
            />
          </mat-form-field>
          <div class="d-flex">
            <mat-form-field class="w-100 pe-2">
              <mat-label for="pilot-first-name">
                {{ 'pilotsService::Name' | abpLocalization }}
              </mat-label>

              <input id="pilot-first-name" formControlName="firstName" maxlength="100" matInput />
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label for="pilot-last-name">
                {{ 'pilotsService::LastName' | abpLocalization }}
              </mat-label>

              <input id="pilot-last-name" formControlName="lastName" maxlength="100" matInput />
            </mat-form-field>
          </div>

          <div class="d-flex">
            <mat-form-field class="w-100 pe-2">
              <mat-label>
                {{ 'pilotsService::Gender' | abpLocalization }}
              </mat-label>

              <mat-select formControlName="genderId">
                <mat-option [value]="null"></mat-option>
                <mat-option [value]="gender.id" *ngFor="let gender of genders">
                  {{ gender.name | abpLocalization }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>
                {{ 'pilotsService::Ethnicity' | abpLocalization }}
              </mat-label>

              <mat-select formControlName="ethnicityId">
                <mat-option [value]="null"></mat-option>
                <mat-option [value]="ethnicity.id" *ngFor="let ethnicity of ethnicities">
                  {{ ethnicity.name | abpLocalization }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-form-field class="w-100">
            <mat-label for="pilot-email">
              {{ 'pilotsService::Email' | abpLocalization }}
            </mat-label>

            <input id="pilot-email" [value]="pilot.email" maxlength="100" matInput disabled />
          </mat-form-field>

          <mat-form-field class="w-100 mb-2">
            <mat-label for="pilot-FAA107">
              {{ 'pilotsService::FAA' | abpLocalization }}
            </mat-label>

            <input id="pilot-FAA107" formControlName="part107Number" maxlength="7" matInput />
            <mat-error *ngIf="form.get('part107Number').hasError('pattern')">
              {{ 'Flyguys::FAAPatternValidation' | abpLocalization }}
            </mat-error>
          </mat-form-field>

          <span
            class="text-danger mb-3 d-block"
            *ngIf="
              form.get('part107Number')?.errors?.cPattern &&
              !form.get('part107Number')?.errors?.required
            "
            >{{ form.get('part107Number')?.errors?.cPattern.message | abpLocalization }}</span
          >

          <div class="d-flex">
            <div class="w-100 pe-2">
              <flg-phone-input
                [widthClass]="'w-100'"
                [control]="form.get('phone')"
                [label]="'pilotsService::PrimaryPhoneNumber' | abpLocalization"
              ></flg-phone-input>
            </div>

            <div class="w-100">
              <flg-phone-input
                [widthClass]="'w-100'"
                [control]="form.get('secondaryPhone')"
                [label]="'pilotsService::SecondaryPhoneNumber' | abpLocalization"
              ></flg-phone-input>
            </div>
          </div>

          <div class="w-100">
            <mat-checkbox formControlName="enableSMSReception" color="primary"></mat-checkbox>
            <label class="form-label" for="enableSMSReception">{{
              'IdentityService::EnableSMSReception' | abpLocalization
            }}</label>
          </div>

          <div class="w-100" class="checkDescription">
            <span>
              {{ 'IdentityService::DescriptionEnableSMSReception' | abpLocalization }}
            </span>
          </div>

          <flg-address-input
            formControlName="address"
            (selectedAddress)="handleSelectedAddress($event)"
            (cleared)="clearAddressForm($event)"
            [allowedCountries]="allowedCountries"
          ></flg-address-input>

          <div class="d-flex">
            <mat-form-field class="w-100 pe-2">
              <mat-label>
                {{ 'pilotsService::Street' | abpLocalization }}
              </mat-label>

              <input id="pilot-address-street" formControlName="addressStreet" matInput />
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>
                {{ 'pilotsService::City' | abpLocalization }}
              </mat-label>

              <input id="pilot-address-city" formControlName="addressCity" matInput />
            </mat-form-field>
          </div>

          <div class="d-flex">
            <mat-form-field class="w-100 pe-2">
              <mat-label>Country</mat-label>
              <mat-select
                name="contries"
                (selectionChange)="handleCountrySelection($event.value)"
                formControlName="addressCountryId"
              >
                <mat-option
                  *ngFor="let c of dataCountries.items"
                  [value]="c.id"
                  title="{{ c.description }}"
                >
                  {{ c.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>State</mat-label>
              <mat-select
                name="state"
                (selectionChange)="handleStateSelection($event.value)"
                formControlName="addressStateId"
              >
                <mat-option
                  *ngFor="let st of currentStates"
                  [value]="st.id"
                  title="{{ st.description }}"
                >
                  {{ st.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="d-flex">
            <mat-form-field class="w-100 pe-2">
              <mat-label>
                {{ 'pilotsService::ZipCode' | abpLocalization }}
              </mat-label>

              <input id="pilot-address-zipcode" formControlName="addressZipCode" matInput />
            </mat-form-field>

            <div class="w-100"></div>
          </div>

          <mat-form-field class="w-100">
            <mat-label for="pilot-secondary-address">
              {{ 'pilotsService::SecondaryAddress' | abpLocalization }}
            </mat-label>

            <input
              id="pilot-secondary-address"
              formControlName="secondaryAddress"
              maxlength="500"
              matInput
            />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label>
              {{ 'pilotsService::Availability' | abpLocalization }}
            </mat-label>

            <mat-select formControlName="availabilityId">
              <mat-option [value]="null"></mat-option>
              <mat-option
                [value]="availability.id"
                *ngFor="let availability of availabilities.items"
              >
                {{ availability.description | abpLocalization }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3" modalActions>
      <button
        mat-flat-button
        class="outline-button me-2"
        type="button"
        (click)="hideForm()"
        [disabled]="isModalBusy"
      >
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        (click)="submitForm()"
        [disabled]="form.invalid || isModalBusy"
      >
        {{ 'AbpUi::Save' | abpLocalization }}
      </button>
    </div>
  </flg-generic-modal>
</ng-template>
