import { Component, Input } from '@angular/core';
import { MissionPilotsPortalDto } from '../../../models/missions/missions-pilot-portal-dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-compact-mission-card',
  templateUrl: './compact-mission-card.component.html',
  styleUrls: ['./compact-mission-card.component.scss'],
})
export class CompactMissionCardComponent {
  private readonly DEFAULT_URI = '00000000-0000-0000-0000-000000000000';
  @Input() mission: MissionPilotsPortalDto;
  @Input() captureDate: string;

  constructor(private router: Router) {}

  /**
   * Routes to the mission detail
   */
  navigate() {
    const missionCoordinatorId = this.mission.missionCoordinatorId ?? this.DEFAULT_URI;

    this.router.navigate([
      '/mission-details',
      this.mission.missionId,
      this.mission.captureId,
      missionCoordinatorId,
    ]);
  }
}
