import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/',
        name: 'pilotsService::MyMissions',
        iconClass: 'webhook',
        order: 1,
        layout: eLayoutType.application,
      },
      {
        path: '/awaiting-response',
        name: 'pilotsService::MyMissionsFinder',
        iconClass: 'radar',
        order: 2,
        layout: eLayoutType.application,
      },
      {
        path: '/notifications',
        name: 'pilotsService::MyNotifications',
        iconClass: 'notifications',
        order: 3,
        layout: eLayoutType.application,
      },
      {
        path: '/chat',
        name: 'Chat',
        iconClass: 'chat',
        order: 4,
        layout: eLayoutType.application,
        requiredPolicy: 'Chat.Messaging',
      },
      {
        path: '/sftp',
        name: 'SFTP',
        iconClass: 'radar',
        order: 5,
        layout: eLayoutType.application,
        requiredPolicy: 'FileManagementService.SftpUsers',
      },
      //TODO: check ABP routing because if we not use providers, the navigation fails. Ticket: https://dev.azure.com/newtonvision/FLYGUYS/_workitems/edit/10051
      {
        path: '/500',
        name: 'Internal Server Error',
        iconClass: 'error',
        order: 10,
        layout: eLayoutType.application,
        requiredPolicy: 'CustomHack',
      },
      {
        path: '/forbidden',
        name: 'Forbidden',
        iconClass: 'error',
        order: 11,
        layout: eLayoutType.application,
        requiredPolicy: 'CustomHack',
      },
      {
        path: '/no-internet',
        name: 'NoInternet',
        iconClass: 'error',
        order: 12,
        layout: eLayoutType.application,
        requiredPolicy: 'CustomHack',
      },
      {
        path: 'help',
        name: 'Help',
        iconClass: 'help',
        order: 5,
        layout: eLayoutType.application,
      },
      //comment this by QA Env - Pilots Portal & Customers Portal users should not be seeing the "Health" page - 19859
      // https://dev.azure.com/newtonvision/FLYGUYS/_workitems/edit/19859
      // {
      //   path: '/health',
      //   name: 'Health',
      //   iconClass: 'favorite',
      //   order: 999,
      //   layout: eLayoutType.application,
      //   requiredPolicy: 'General.ShowMenu.Administration',
      // },
    ]);
  };
}
