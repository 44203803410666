<ng-container *ngIf="filterConfig">
  <div class="container">
    <!-- Header and Close Button -->
    <div class="row mt-2 mb-3">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <h3 class="mb-0">{{ filterTitle || 'Filter Orders' }}</h3>
        <span class="material-symbols-outlined cursor-pointer" (click)="onClose()"> close </span>
      </div>
    </div>

    <!-- Filter Column Selector -->
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-label>Column</mat-label>
          <mat-select [(value)]="selectedColumn" (selectionChange)="onColumnChange($event.value)">
            <mat-option
              *ngFor="let condition of filterConfig.conditions"
              [value]="condition.column"
            >
              {{ condition.columnDisplayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="selectedFilterCondition">
      <!-- Dropdown Filter -->
      <div class="col-12" *ngIf="selectedFilterCondition?.type === filterType.Dropdown">
        <mat-form-field class="w-100">
          <mat-label>{{ selectedFilterCondition.columnDisplayName }}</mat-label>
          <mat-select [(value)]="selectedFilterCondition.newValue">
            <mat-option *ngFor="let option of selectedFilterCondition.options" [value]="option?.id">
              {{ option?.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Text Filter -->
      <div class="col-12" *ngIf="selectedFilterCondition.type === filterType.Text">
        <mat-form-field class="w-100">
          <input
            matInput
            [placeholder]="selectedFilterCondition.columnDisplayName"
            [(ngModel)]="selectedFilterCondition.newValue"
          />
        </mat-form-field>
      </div>

      <!-- Boolean Filter -->
      <div class="col-12" *ngIf="selectedFilterCondition.type === filterType.Boolean">
        <mat-slide-toggle
          class="mb-3"
          color="accent"
          [checked]="selectedFilterCondition.newValue === 'True'"
          (change)="updateBooleanValue($event.checked)"
        >
          {{ selectedFilterCondition.columnDisplayName }}
        </mat-slide-toggle>
      </div>

      <!-- Numeric Filter -->
      <div class="col-12" *ngIf="selectedFilterCondition.type === filterType.Numeric">
        <mat-form-field class="w-100">
          <input
            matInput
            [placeholder]="selectedFilterCondition.columnDisplayName"
            [(ngModel)]="selectedFilterCondition.newValue"
            digitOnly
            pattern="[0-9]*"
          />
        </mat-form-field>
      </div>

      <!-- Date Filter -->
      <div class="col-12" *ngIf="selectedFilterCondition.type === filterType.Date">
        <div class="row">
          <div class="col-6">
            <mat-form-field class="w-100 mb-3">
              <mat-label>From</mat-label>
              <input
                #fromInput
                matInput
                [matDatepicker]="pickerFrom"
                (dateChange)="onDateChange($event.value, 'from')"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="w-100 mb-3">
              <mat-label>To</mat-label>
              <input
                matInput
                #toInput
                [matDatepicker]="pickerTo"
                (dateChange)="onDateChange($event.value, 'to')"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
              <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- Add New Condition Button -->
    <div class="row mb-3">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            mat-button
            color="primary"
            class="add-condition-button"
            (click)="addNewCondition()"
            [disabled]="!canAddCondition()"
          >
            <span class="material-symbols-outlined align-middle me-1">add</span>
            Add New Condition
          </button>
        </div>
      </div>
    </div>

    <mat-divider class="mb-3 mt-2"></mat-divider>

    <div class="row mb-3">
      <div class="col-12">
        <span class="fg-subtitle">Summary</span>
        <ng-container *ngFor="let condition of filterConfig.conditions; let i = index">
          <div
            *ngIf="conditionUpdates[condition.column].length"
            class="d-flex flex-wrap align-items-center border-blue-rounded filter-container p-2 mt-3 close-container"
          >
            <div class="filter-container-wrapper">
              <span class="fg-subtitle">{{ condition.columnDisplayName }}:&nbsp;</span>
              <ng-container *ngIf="condition.type === filterType.Dropdown">
                <span
                  *ngFor="let value of conditionUpdates[condition.column]; let isLast = last"
                  class="main-color fw-medium"
                >
                  {{ getDescription(condition.options, value)
                  }}<ng-container *ngIf="!isLast">,</ng-container>
                </span>
              </ng-container>

              <ng-container *ngIf="condition.type !== filterType.Dropdown">
                <span
                  *ngFor="let value of conditionUpdates[condition.column]; let isLast = last"
                  class="main-color fw-medium"
                >
                  <ng-container *ngIf="condition.type !== filterType.Boolean">{{
                    value
                  }}</ng-container>
                  <ng-container *ngIf="condition.type === filterType.Boolean">{{
                    value == 'true' || value == 'True' ? 'Yes' : 'No'
                  }}</ng-container>
                  <ng-container *ngIf="!isLast">,</ng-container>
                </span>
              </ng-container>
            </div>
            <span
              (click)="handleOnRemoveCriteria(i)"
              class="ms-auto cursor-pointer material-symbols-rounded close-element"
              title="Remove Filter"
            >
              delete
            </span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <button
          mat-flat-button
          color="primary"
          class="common-button apply-button"
          (click)="onApplyFilters()"
          [disabled]="!isConditionAdded"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</ng-container>
