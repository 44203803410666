import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, from } from 'rxjs';

import { ToggleService } from '../../common/header/toggle.service';
import { CustomizerSettingsService } from '../../tagus/customizer-settings/customizer-settings.service';

@Component({
  selector: 'app-application-layout',
  templateUrl: './application-layout.component.html',
  styleUrls: ['./application-layout.component.scss'],
})
export class ApplicationLayoutComponent {
  @ViewChild('appContainer') container: ElementRef;

  title = 'Fly Guys - Data from Above!';

  isToggled = false;

  observer: ResizeObserver;

  constructor(
    public router: Router,
    private toggleService: ToggleService,
    public themeService: CustomizerSettingsService,
    private zone: NgZone
  ) {
    this.toggleService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
  }

  ngAfterViewInit(): void {
    this.observer = new ResizeObserver(entries => {
      const entries$ = from(entries);

      this.zone.run(() => {
        entries$.pipe(debounceTime(300)).subscribe({
          next: (entry: ResizeObserverEntry) => {
            if (entry) {
              window.dispatchEvent(new Event('resize'));
            }
          },
        });
      });
    });

    this.observer.observe(this.container.nativeElement);
  }
  ngOnDestroy(): void {
    this.observer.observe(this.container.nativeElement);
  }
}
