import { NgModule, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';

import { GridComponent } from './components/grid/grid.component';
import { environment } from '../environments/environment.prod';

import { RedirectGuardComponent } from './guards/redirect.component';
import { AuthGuard, EnvironmentService, PermissionGuard } from '@abp/ng.core';

import { FinancialsComponent } from './pilot-sourcing/components/financials/financials.component';
import { InternalServerErrorComponent } from './shared/custom-error-pages/internal-server-error/internal-server-error.component';
import { ForbiddenComponent } from './shared/custom-error-pages/forbidden/forbidden.component';
import { NotFoundComponent } from './shared/custom-error-pages/not-found/not-found.component';
import { NoInternetComponent } from './shared/custom-error-pages/no-internet/no-internet.component';
import { SftpFileManagementComponent } from 'projects/file-management/src/lib/components/sftp-file-management/sftp-file-management.component';

const getElsaUrlResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const { apis } = inject(EnvironmentService).getEnvironment();
  return apis['elsa']?.url || environment.apis.elsa.url;
};

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/main/main.module').then(m => m.MainModule),
  },
  { path: '500', component: InternalServerErrorComponent, pathMatch: 'full' },
  { path: 'forbidden', component: ForbiddenComponent, pathMatch: 'full' },
  { path: 'no-internet', component: NoInternetComponent, pathMatch: 'full' },
  // {
  //   path: 'sftp',
  //   component: SftpFileManagementComponent,
  //   canActivate: [AuthGuard, PermissionGuard],
  // },
  {
    path: 'wizard',
    loadChildren: () => import('./components/wizard/wizard.module').then(m => m.WizardModule),
    canActivate: [AuthGuard, PermissionGuard],
  },
  {
    path: 'validate',
    loadChildren: () =>
      import('./components/role-validation/role-validation.module').then(
        m => m.RoleValidationModule,
      ),
    canActivate: [AuthGuard, PermissionGuard],
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'product-service',
    loadChildren: () => import('product-service').then(m => m.ProductServiceModule),
  },
  {
    path: 'core-service',
    loadChildren: () => import('core-service').then(m => m.CoreServiceModule.forLazy()),
  },
  // {
  //   path: 'text-template-management',
  //   loadChildren: () =>
  //     import('text-template-management').then(m => m.TextTemplateManagementModule.forLazy()),
  // },
  {
    path: 'file-management',
    loadChildren: () =>
      import('projects/file-management/src/lib').then(m => m.FileManagementModule.forLazy()),
  },
  {
    path: 'grid',
    component: GridComponent,
  },
  {
    path: 'financials',
    component: FinancialsComponent,
  },
  {
    path: 'missions-service',
    loadChildren: () => import('missions-service').then(m => m.MissionsServiceModule.forLazy()),
  },
  {
    path: 'pilots-service',
    loadChildren: () => import('pilots-service').then(m => m.PilotsServiceModule.forLazy()),
  },
  {
    path: 'customers-service',
    loadChildren: () => import('customers-service').then(m => m.CustomersServiceModule.forLazy()),
  },
  {
    path: 'notifications-service',
    loadChildren: () =>
      import('notifications-service').then(m => m.NotificationsServiceModule.forLazy()),
  },
  {
    path: 'elsa',
    canActivate: [AuthGuard, PermissionGuard],
    component: RedirectGuardComponent,
    resolve: {
      externalUrl: getElsaUrlResolver,
    },
  },
  {
    path: 'impersonate',
    loadChildren: () =>
      import('./components/impersonate/impersonate.module').then(m => m.ImpersonateModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
