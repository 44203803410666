import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ABP, ListService, TrackByService, PagedResultDto } from '@abp/ng.core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize, map, startWith, tap } from 'rxjs/operators';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { PilotSourcingService } from '../../../../../app/services/pilot-sourcing.service';
import { PilotsEquipments } from '../../../models/pilot-sourcing-dto';
import { PilotRequest } from '../../../models/pilot-for-sorucing-request';
import { PilotInventoriesService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/relationals';
import {
  EquipmentsDto,
  GetEquipmentInput,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { EquipmentsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { enumState } from 'projects/missions-service/src/lib/proxy/missions-service/shared';
import { ColumnAction } from 'projects/flyguys-pilot/src/app/components/columns/components/column-actions/column-actions.component';
import { ColumnColoringRules } from 'projects/flyguys-pilot/src/app/components/columns/components/column-stylizer/column-stylizer.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'projects/flyguys-pilot/src/app/components/common/confirm-dialog/confirm.dialog.component';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import { SensorTypesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { PilotDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import {
  GetSensorTypesInput,
  SensorTypesDto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';

@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  styleUrls: ['./equipments.component.scss'],
})
export class EquipmentsComponent implements OnInit {
  @ViewChild('modal') modal: TemplateRef<any>;
  @Input() pilotId: string;

  stateRules: ColumnColoringRules[] = [
    {
      columnValue: 0,
      softValue: 'Disabled',
      valueClass: 'badge',
      valueStyle: {
        'background-color': 'rgb(255, 230, 230)',
        color: 'crimson',
        'border-radius': '0.2rem',
      },
    },
    {
      columnValue: 1,
      softValue: 'Enabled',
      valueClass: 'badge',
      valueStyle: {
        'background-color': 'rgb(230, 255, 230)',
        color: 'green',
        'border-radius': '0.2rem',
      },
    },
  ];

  equipments: PagedResultDto<PilotsEquipments> = {
    items: [],
    totalCount: 0,
  };

  filteredEquipment: Observable<EquipmentsDto[]>;

  filteredSensorTypes: Observable<SensorTypesDto[]>;

  missionEquipments: PagedResultDto<EquipmentsDto> = {
    items: [],
    totalCount: 0,
  };

  sensorTypes: PagedResultDto<SensorTypesDto> = {
    items: [],
    totalCount: 0,
  };

  form: FormGroup;
  isModalBusy = false;
  isEditing = false;
  equipmentExists: boolean = false;

  infoPilot: PilotDto;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: PilotSourcingService,
    public readonly pilotInventoriesService: PilotInventoriesService,
    public readonly equipmentsService: EquipmentsService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public readonly pilotService: PilotService,
    public readonly sensorTypesService: SensorTypesService,
  ) {}

  ngOnInit() {
    var filters = { pilotId: this.pilotId } as PilotRequest;
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getEquipments({
        ...query,
        ...filters,
      });

    const setData = (list: PagedResultDto<PilotsEquipments>) => (this.equipments = list);

    this.list.hookToQuery(getData).subscribe(setData);

    var equipmentsFilters = { state: enumState.Enabled } as GetEquipmentInput;
    this.equipmentsService
      .getList({
        ...equipmentsFilters,
      })
      .subscribe(res => (this.missionEquipments = res));

    var sensorTypesFilters = { state: enumState.Enabled } as GetSensorTypesInput;
    this.sensorTypesService
      .getList({
        ...sensorTypesFilters,
      })
      .subscribe(res => (this.sensorTypes = res));

    this.pilotService.get(this.pilotId).subscribe(res => {
      this.infoPilot = res;
    });

    this.buildForm();

    this.filteredEquipment = this.form.get('equimentId').valueChanges.pipe(
      startWith(''),
      map(value => this.filterEquipment(value || '')),
    );
  }

  buildForm() {
    this.form = this.fb.group({
      id: [],
      equimentId: [null, [Validators.required]],
      pilotId: [this.pilotId, [Validators.required]],
      state: [1, [Validators.required]],
      fAARegistration: [null, []],
      status: [null, []],
    });
  }

  hideForm() {
    this.isEditing = false;
    this.dialog.closeAll();
    this.form.reset();
  }

  restoreForm() {
    this.form.get('id').patchValue('');
  }

  showForm(equipment?: PilotsEquipments) {
    this.form.get('pilotId').patchValue(this.pilotId);

    if (equipment) {
      this.form.get('id').patchValue(equipment.id);
      this.form.get('equimentId').patchValue(equipment.equipmentId);
      this.form.get('state').patchValue(equipment.state);
      this.form.get('fAARegistration').patchValue(equipment.faaRegistration);
      this.form.get('status').patchValue(equipment.status);

      this.isEditing = true;
    }

    this.dialog.open(this.modal, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  deleteEquipment(equipment: PilotsEquipments) {
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete equipment?',
        actions: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      },
      disableClose: true,
      width: '400px',
    });

    ref.afterClosed().subscribe({
      next: (shouldDelete: boolean) => {
        if (shouldDelete) {
          this.isModalBusy = true;

          const _equipments = this.missionEquipments.items.find(e => e.id == equipment.equipmentId);

          if (_equipments != null) {
            const _aSensorTypes = _equipments.sensorTypes;

            if (_aSensorTypes != null && _aSensorTypes.length > 0) {
              _aSensorTypes.forEach(sensorType => {
                const itemSensorType = this.sensorTypes.items.find(st => st.id == sensorType);

                if (this.infoPilot.sensorsType != null && this.infoPilot.sensorsType != '') {
                  if (this.infoPilot.sensorsType.indexOf(itemSensorType.description) != -1) {
                    this.infoPilot.sensorsType = this.infoPilot.sensorsType.replace(
                      ',' + itemSensorType.description,
                      '',
                    );
                    this.infoPilot.sensorsType = this.infoPilot.sensorsType.replace(
                      itemSensorType.description,
                      '',
                    );
                  }
                }
              });

              const requestPilot = this.pilotService.update(this.pilotId, this.infoPilot);

              requestPilot.subscribe();
            }
          }

          const request = this.pilotInventoriesService.delete(equipment.id);

          request.pipe(finalize(() => (this.isModalBusy = false))).subscribe(this.list.get);
        }
      },
    });
  }

  submitForm() {
    const equipmentId = this.form.get('equimentId').value;
    const FAARegistration = this.form.get('fAARegistration').value;

    const existingEquipment = this.equipments.items.find(
      e => e.equipmentId === equipmentId && e.faaRegistration === FAARegistration,
    );

    this.equipmentExists = !!existingEquipment;

    if (this.equipmentExists) {
      return;
    }

    const _equipments = this.missionEquipments.items.find(e => e.id == equipmentId);

    if (_equipments != null) {
      const _aSensorTypes = _equipments.sensorTypes;

      if (_aSensorTypes != null && _aSensorTypes.length > 0) {
        _aSensorTypes.forEach(sensorType => {
          const itemSensorType = this.sensorTypes.items.find(st => st.id == sensorType);

          if (this.infoPilot.sensorsType != null && this.infoPilot.sensorsType != '') {
            if (this.infoPilot.sensorsType.indexOf(itemSensorType.description) == -1) {
              this.infoPilot.sensorsType =
                this.infoPilot.sensorsType + ',' + itemSensorType.description;
            }
          } else {
            this.infoPilot.sensorsType = itemSensorType.description;
          }
        });

        const requestPilot = this.pilotService.update(this.pilotId, this.infoPilot);

        requestPilot.subscribe();
      }
    }

    if (this.isEditing) {
      const request = this.pilotInventoriesService.update(
        this.form.get('id').value,
        this.form.value,
      );

      this.isModalBusy = true;

      request
        .pipe(
          finalize(() => {
            this.isModalBusy = false;
            this.restoreForm();
          }),
          tap(() => this.hideForm()),
        )
        .subscribe(this.list.get);
    } else {
      const request = this.pilotInventoriesService.create(this.form.value);

      this.isModalBusy = true;

      request
        .pipe(
          finalize(() => (this.isModalBusy = false)),
          tap(() => this.hideForm()),
        )
        .subscribe(this.list.get);
    }
  }

  submitDeleteForm() {
    // if (this.formDelete.invalid) return;
    // const request = this.pilotInventoriesService.delete(this.formDelete.get('id').value);
    // this.isModalBusyDelete = true;
    // request
    //   .pipe(
    //     finalize(() => (this.isModalBusyDelete = false)),
    //     tap(() => this.hideFormDelete())
    //   )
    //   .subscribe(this.list.get);
  }

  columnActions(row: PilotsEquipments) {
    let columnActions: ColumnAction[] = [
      {
        actionIcon: 'edit',
        abpPermission: '',
        action: {
          callAction: () => {
            this.showForm(row);
          },
        },
      },
      {
        actionIcon: 'delete',
        abpPermission: '',
        action: {
          callAction: () => {
            this.deleteEquipment(row);
          },
        },
      },
    ];

    return columnActions;
  }

  /**
   * Takes the selected id and looks into the current collection to extract its display value
   * @param selectedId string
   * @returns
   */
  formatEquipment(selectedId: string) {
    return this.missionEquipments.items?.find(item => item.id === selectedId)?.name;
  }

  /**
   * Filters local equipment collection to match against autocomplete
   * @param equipment
   * @returns EquipmentsDto[]
   */
  filterEquipment(equipment: string = ''): EquipmentsDto[] {
    const searchValue = equipment.toLocaleLowerCase();

    return this.missionEquipments.items?.filter(option =>
      option.name.toLowerCase().includes(searchValue),
    );
  }
}
