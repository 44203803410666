import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { ConfigStateService, CurrentUserDto, EnvironmentService } from '@abp/ng.core';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  appInsights: ApplicationInsights;
  constructor(
    private stateService: ConfigStateService,
    private environment: EnvironmentService,
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: this.environment.getEnvironment().appInsights.connectionString,
        enableAutoRouteTracking: true, // option to log all route changes
      },
    });
    this.appInsights.loadAppInsights();
  }

  private enrichProperties(properties: any) {
    try {
      if (!properties.application) properties.application = environment.application.name;

      if (!properties.referrer)
        properties.referrer =
          window?.location?.href ?? document?.referrer ?? environment.application.baseUrl;

      if (!properties.userId) {
        const currentUser = this.stateService?.getDeep('currentUser') as CurrentUserDto;

        if (currentUser) {
          properties.userName = currentUser?.impersonatorUserName ?? currentUser?.email;
          properties.userId = currentUser?.impersonatorUserId ?? currentUser?.id;

          properties.userDetails = {
            id: currentUser?.id,
            email: currentUser?.email,
            impersonatorUserId: currentUser?.impersonatorUserId,
            impersonatorUserName: currentUser?.impersonatorUserName,
          };
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  logPageView(name?: string, url?: string) {
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.enrichProperties(properties);
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.enrichProperties(properties);
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.enrichProperties(properties);
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
