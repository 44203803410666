<div class="px-2 py-1 mt-1 mb-3 bg-white border rounded fg-border-card w-100">
  <div class="col-lg-12 col-sm-12 col-12">
    <div class="row">
      <div class="col-lg-9 col-sm-12 p-2">
        <div class="d-lg-flex align-items-center mb-2">
          <div
            class="d-flex me-1 align-items-center cursor-pointer card-text"
            (click)="navigateToDetail()"
          >
            <span class="card-subtitle me-1 fw-light lh-sm">Job ID: </span>
            <span class="me-2">{{ mission.jobId }}</span>
          </div>
          <span
            class="d-lg-flex align-items-center m-0 cursor-pointer fw-bold card-text fs-4 lh-sm"
            (click)="navigateToDetail()"
          >
            {{ mission.missionName }}
            <span class="badge {{ statusColor }} soft d-inline ms-lg-2 lh-sm">{{ status }}</span>
          </span>
        </div>

        <!-- Primary Action Slot -->
        <ng-content select="[primaryAction]"></ng-content>
      </div>
      <div class="col-lg-3 col-sm-12">
        <div class="d-flex justify-content-end">
          <a class="cursor-pointer h5 mission-detail-link lh-sm" (click)="navigateToDetail()">{{
            'missionsService::MissionDetailsOpen' | abpLocalization
          }}</a>
        </div>

        <!-- Secondary Action Slot -->
        <ng-content select="[secondaryAction]"></ng-content>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <mat-divider class="mt-1 mb-1"></mat-divider>
      </div>
    </div>

    <div class="row">
      <div class="d-flex p-1 align-items-center">
        <div
          class="d-flex align-items-center me-lg-3 card-text cursor-pointer"
          (click)="showDrivingDistance()"
        >
          <span class="material-symbols-rounded">distance</span>
          <span class="card-subtitle fg-subtitle fs-6 fw-light me-lg-2 lh-sm"
            >{{ 'missionsService::Address' | abpLocalization }}:</span
          >
          <span class="fs-5 lh-sm">{{ mission.address }}</span>
        </div>

        <div class="d-flex align-items-center me-lg-3 card-text">
          <span class="material-symbols-rounded">calendar_today</span>
          <span class="card-subtitle fg-subtitle fs-6 fw-light me-lg-2 lh-sm"
            >{{ 'missionsService::CaptureDate' | abpLocalization }}:</span
          >
          <span class="fs-5 lh-sm">{{
            mission.fixedCaptureDate || (getCaptureDate(mission) | date: 'MM/dd/yyyy')
          }}</span>
        </div>

        <div class="d-flex align-items-center card-text">
          <span class="material-symbols-rounded">schedule</span>
          <span class="card-subtitle fg-subtitle fs-6 fw-light me-lg-2 lh-sm"
            >{{ 'missionsService::CaptureTime' | abpLocalization }}:</span
          >
          <span class="fs-5 lh-sm"
            >{{
              mission.fixedCaptureTime ||
                (getCaptureTime(mission) | date: 'hh:mm a' || '--:--') ||
                '--:--'
            }}
            {{ mission.timeZone }}</span
          >
        </div>
      </div>
      <div class="d-flex align-items-center card-text">
        <span class="card-subtitle fg-subtitle fs-6 fw-light me-lg-2 lh-sm"
          >{{ 'missionsService::Airspace Waiver Required' | abpLocalization }}:</span
        >
        <span class="fs-5 lh-sm">{{
          mission.manualAirspaceWaiverRequired ? 'Required' : 'Not Required'
        }}</span>
      </div>
      <ng-content select="[pilotPaySlot]"></ng-content>
    </div>
  </div>
</div>
