import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EnvironmentService, RoutesService } from '@abp/ng.core';
import { eThemeSharedRouteNames } from '@abp/ng.theme.shared';

import { ReplaceableComponentsService } from '@abp/ng.core';

import { eThemeLeptonXComponents } from '@volosoft/abp.ng.theme.lepton-x';
// import { eAccountComponents } from '@volo/abp.ng.account/public';

// Layout
import { ApplicationLayoutComponent } from './components/layout/application-layout/application-layout.component';

// Account
// import { AccountLayoutComponent } from './account/account-layout/account-layout.component';
// import { LoginComponent } from './account/login/login.component';
// import { RegisterComponent } from './account/register/register.component';
// import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';

// Application Insights
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '../environments/environment';
import { ParallelFileUploadService } from 'projects/file-management/src/lib/services/parallel-file-upload.service';
import { ClarityService } from './services/clarity-service';
import { ImpersonateService } from './services/impersonate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  cookieAccessToken = 'cookie_access_token';
  cookieIdToken = 'cookie_id_token';
  cookieIdUser = 'cookie_id_user';
  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    private router: Router,
    private routes: RoutesService,
    public _parallelFileUploadService: ParallelFileUploadService,
    public _clarityService: ClarityService,
    private environment: EnvironmentService,
  ) {
    this.routes.patch(eThemeSharedRouteNames.Administration, { invisible: true });

    // Replace main layout
    this.replaceableComponents.add({
      component: ApplicationLayoutComponent,
      key: eThemeLeptonXComponents.ApplicationLayout,
    });

    // // Replace account layout
    // this.replaceableComponents.add({
    //   component: AccountLayoutComponent,
    //   key: eThemeLeptonXComponents.AccountLayout,
    // });

    // // Replace login layout
    // this.replaceableComponents.add({
    //   component: LoginComponent,
    //   key: eAccountComponents.Login,
    // });

    // // Replace register layout
    // this.replaceableComponents.add({
    //   component: RegisterComponent,
    //   key: eAccountComponents.Register,
    // });

    // // Replace forgot password layout
    // this.replaceableComponents.add({
    //   component: ForgotPasswordComponent,
    //   key: eAccountComponents.ForgotPassword,
    // });

    const angularPlugin = new AngularPlugin();

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: this.environment.getEnvironment().appInsights.connectionString,
        extensions: [angularPlugin as any],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();

    this.start();
  }

  ngOnInit(): void {
    this._clarityService.load();
    let path = window.location.pathname;

    this.impersonatePilot(isImpersonate => {
      if (!isImpersonate) {
        this.router.navigate(['/validate'], {
          queryParams: {
            path: path,
          },
        });
      }
    });
  }

  private start(): void {
    window.addEventListener('beforeunload', this.canLeavePage.bind(this));
  }

  private stop(): void {
    window.removeEventListener('beforeunload', this.canLeavePage.bind(this));
  }

  canLeavePage($event: any): boolean {
    $event.returnValue = this._parallelFileUploadService.getIsRunning()
      ? 'leaving this page you will lose the data that is not uploaded, do you want to continue?'
      : '';
    return false;
  }

  ngOnDestroy() {
    this.stop();
  }
  impersonatePilot(cb = (isImpersonate: boolean) => {}) {
    const cookieAccessToken = this.obtenerCookie(this.cookieAccessToken);
    const cookieUserId = this.obtenerCookie(this.cookieIdUser);

    if (
      cookieAccessToken &&
      cookieAccessToken != 'expired' &&
      cookieUserId &&
      cookieUserId != 'expired'
    ) {
      this.router.navigate(['/impersonate']);
      cb(true);
    } else {
      console.log('Not found cookieAccessToken');
      cb(false);
    }
  }

  obtenerCookie(nombre: string) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [key, value] = cookie.trim().split('=');
      if (key === nombre) {
        const finalValue = decodeURIComponent(value);
        return finalValue;
      }
    }
    return null;
  }
  delete_cookie(name: string) {
    document.cookie = `${name}=expired;domain=flyguys.com;path=/`;
    //document.cookie = `${name}=expired;domain=.localhost;path=/`;
  }
}
