import { Component, OnInit } from '@angular/core';
import { ABP } from '@abp/ng.core';
import { ActivatedRoute } from '@angular/router';
import { PilotDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';

@Component({
  selector: 'app-pilot-info',
  templateUrl: './pilot-info.component.html',
  styleUrls: ['./pilot-info.component.scss'],
})
export class PilotInfoComponent implements OnInit {
  pilotId: string;
  pilot: PilotDto;

  constructor(
    private route: ActivatedRoute,
    public readonly service: PilotService,
    private identityService: IdentityUserService,
  ) { }

  ngOnInit() {
    this.pilotId = this.route.snapshot.paramMap.get('id');

    if (this.pilotId == undefined) {
      this.service.getProfile().subscribe(result => {
        this.pilot = result;
        this.pilotId = result.id;

        if (this.pilot.userId) {
          this.identityService.get(this.pilot.userId).subscribe(pilot => {
            if (pilot.extraProperties) {
              if (pilot.extraProperties.enableSMSReception) {
                this.pilot.enableSMSReception = pilot.extraProperties.enableSMSReception;
              }
            }
          });
        }
      });
    } else {
      this.service.get(this.pilotId).subscribe(result => {
        this.pilot = result;

        if (this.pilot.userId) {
          this.identityService.get(this.pilot.userId).subscribe(pilot => {
            if (pilot.extraProperties) {
              if (pilot.extraProperties.enableSMSReception) {
                this.pilot.enableSMSReception = pilot.extraProperties.enableSMSReception;
              }
            }
          });
        }
      });
    }
  }

  /**
   * Triggers a resize event to prevent grid collapse
   */
  handleTabChange() {
    setTimeout(_ => window.dispatchEvent(new Event('resize')), 500);
  }
}
